<template>
  <div class="application">
    <!-- kv -->
    <section
      id="kv_content"
      :style="{ backgroundImage: `url( ${require('@/statics/img/banner/apply.png')} )` }"
    >
      <div class="kv_title txt-white">
        <h1>APPLICATION</h1>
        <h2>入會申請</h2>
      </div>
    </section>

    <!-- current stage -->
    <section
      id="stage"
      :style="{ backgroundImage: `url( ${require('@/statics/img/index/kv_bg.png')} )` }"
    >
      <div class="w1300">

        <ApplicationStage />

      </div>
    </section>

    <!-- application description -->
    <section id="apply_description">
      <div class="w1300">

        <div class="success_info txt-center">

          <!-- 選擇信用卡繳費失敗時顯示 -->
          <!-- <p class="txt-center">
            <span id="user_name">王小明</span> 您好，<br><br>
            <span class="txt-bold">由於信用卡繳費失敗</span><br><br>
            請至 <a href="application_payment.php" class="link_back txt-bold">繳納入會費用</a> 重新繳費。
          </p> -->

          <!-- 選擇信用卡繳費成功時顯示 -->
          <p class="txt-center">
            <span id="user_name">{{ realname }}</span> 您好，<br><br>
            <span class="txt-bold">恭喜您已成為台灣居家醫療醫學會的醫師會員!</span><br><br>
            您可登入會員專區使用各項功能，<br>帳號預設為 Email，密碼為身份證字號。
          </p>

          <!-- 選擇非信用卡繳費時顯示 -->
          <!-- <p>
            <span id="user_name">王小明</span> 您好，<br><br>
            您的繳費資料如下，<br><br>繳費後即可登入會員專區使用各項功能，<br><br>帳號預設為 Email，密碼為身份證字號。
          </p> -->

          <!-- 選擇ATM繳費時顯示下方付費資訊區塊 -->
          <!-- <div class="success_detail txt-left">
            <p class="success_detail_title h6 txt-bold">請透過ATM轉帳至以下銀行帳號以完成繳費</p>
            <div class="success_detail_content p">
              <ul class="success_detail_content_title">
                <li>繳費銀行代碼</li>
                <li>繳費帳號</li>
                <li>付款金額</li>
                <li>繳費期限</li>
              </ul>
              <ul class="success_detail_content_text">
                <li>822</li>
                <li>9103522175887271</li>
                <li class="txt-bold"><span>2000</span>元</li>
                <li class="txt-red txt-bold">2020/09/10 08:00</li>
              </ul>
            </div>
          </div> -->

          <!-- 選擇超商繳費時顯示下方付費資訊區塊 -->
          <!-- <div class="success_detail txt-left">
            <p class="success_detail_title h6 txt-bold">請至7-11/全家/萊爾富/OK超商 多媒體機台列印繳費單繳費</p>
            <div class="success_detail_content p">
              <ul class="success_detail_content_title">
                <li>超商繳費代碼</li>
                <li>付款金額</li>
                <li>繳費期限</li>
              </ul>
              <ul class="success_detail_content_text">
                <li>GW130412257496</li>
                <li class="txt-bold"><span>2000</span>元</li>
                <li class="txt-red txt-bold">2020/09/10 08:00</li>
              </ul>
            </div>
          </div> -->

          <router-link to="/login" class="log_in btn">登入</router-link>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import '@/assets/scss/application.scss';

import ApplicationStage from '@/components/ApplicationStage.vue';

export default {
  title: '入會申請',
  name: 'ApplicationSuccess',
  data() {
    return {
      realname: '',
    };
  },
  created() {
    this.realname = this.$cookies.get('realname') || '';
    // request({
    //   url: 'API',
    //   method: 'get',
    //   params: { applyToken: this.$cookies.get('applyToken') },
    // }).then((result) => {
    //   this.realname = result.realname;
    // });
  },
  components: {
    ApplicationStage,
  },
};
</script>
